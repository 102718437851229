* {
  margin: 0;
  padding: 0; }

*,
*::before,
*::after {
  box-sizing: inherit; }



.btn {
  background-image: linear-gradient(to right bottom, #6666ff, #222222);
  border-radius: 4px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-self: center;
  align-items: center;
  float: left;
  margin: 2px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 1px;
  padding-bottom: 2px;
  width: fit-content;
  transition: all .2s; }
  .btn:hover, .btn-small:hover {
    transform: scale(1.05); }
  .btn:focus, .btn-small:focus {
    outline: none; }
  .btn > *:first-child, .btn-small > *:first-child {
    margin-right: 1rem; }


  


body {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
  font-size: 14px;
  margin-top: 0px;
}

img.logo {
  vertical-align: -1px;
  padding-left: 4px;
  padding-right: 4px;
}

span.header-right-align{
  padding-top: 1px;
  padding-right: 4px;
  float: right;
}

span.header-title-text{
  vertical-align: middle;
  font-size: 16px;
}

span.button-pad{
  padding-right: 4px;
}

div.header-box{
  background-color: whitesmoke;
  padding-top: 4px;
  padding-bottom: 5px;
}

.flex_container{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 1em;
}

.event-subcontainer{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1em;
  margin: 1em;
}

.event-name{
  text-align: center;
  margin-bottom: 1em;
}
.error-warning{
  text-align: center;
  margin-bottom: 1em;
  font-weight: bold;
}
.column{
  width: 50%;
  margin: 1em;
}
a {
  color: rebeccapurple;
  font-weight: normal;
  text-decoration: none;
}
a:hover {
  color: darkorchid;
  text-decoration: underline;
}
/* .ReactTable .rt-thead .rt-resizable-header{
  overflow: hidden;
} */

.ReactTable .rt-thead [role="columnheader"] {
outline: 0;
}

.authorized-payments .ReactTable {
  flex-grow: 1;
}

.authorized-payments .alert {
  background-color: rgba(176, 0, 0, 0.5);
  color: #000;
};

.react-tabs {
  background-color: whitesmoke;
}
.react-tabs__tab-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid #aaa;
  background: whitesmoke;
  font-weight: bold;
}
.react-tabs__tab {
  list-style: none;
  padding: 0.7em;
  width: 20%;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}
